import React, {useContext, useEffect, useState} from "react";
import {hideHeaderFooter} from "../../utils/utils";
import {toast} from "react-toastify";
import {subscribeEmail} from "../../utils/router";
import { checkEmail } from "../../utils/utils";
import styles from "./footer.module.scss";
import {useRouter} from "next/router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import { ProductContext } from "../providers/ProductProvider";

const Footer = () => {
    const router = useRouter();
    const {pathname} = router;
    const [newsletterEmail, setNewsletterEmail] = useState("");
    const [hidden, setHidden] = useState(true)
    const { setProductContextValue } = useContext(ProductContext);

    useEffect(() => {
        setHidden(hideHeaderFooter(pathname))
    }, [pathname])


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (checkEmail(newsletterEmail)) {
            try {
                await subscribeEmail(newsletterEmail)
                setNewsletterEmail('')
                toast.success("Got it, please go to your inbox to confirm your email.")
            } catch (e) {
                console.error(e);
                toast.error("Failed to subscribe to our newsletter")
            }
        }
    };

    const resetPromotion = () => {
        localStorage.removeItem('isPromotional')
        setProductContextValue(ctx => ({ ...ctx, isPromotional: false }));
    }

    if (hidden) return null

    return (
        <div className={styles.footer}>
            <div className={styles.footer_container}>
                <div className={styles.footer_brand}>
                    <img className={styles.footer_brand_logo} src="/assets/footer_logo.svg" alt="Brand"/>
                    <form className={styles.footer_newsletter} onSubmit={handleFormSubmit}>
                        <label htmlFor="newsletter">Subscribe to our newsletter</label>
                        <div className={styles.footer_newsletter_input}>
                            <input
                                type="email"
                                name="newsletter"
                                id="newsletter"
                                placeholder="Email address"
                                autoComplete="email"
                                value={newsletterEmail}
                                onChange={({target}) => setNewsletterEmail(target.value)}
                            />
                            <button type="submit"><FontAwesomeIcon icon={faAngleRight}/></button>
                        </div>
                    </form>
                    <div className={styles.footer_socials}>
                        <a href="https://www.tiktok.com/@upfor.love?is_from_webapp=1&sender_device=pc" target="_blank" rel="noreferrer">
                            <img src="/assets/TikTok.svg" alt="TikTok"/>
                        </a>
                        <a href="https://instagram.com/upfor.love?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
                            <img src="/assets/Instagram.svg" alt="Instagram"/>
                        </a>
                        <a href="https://mobile.twitter.com/upforlove_" target="_blank" rel="noreferrer">
                            <img src="/assets/Twitter.svg" alt="Twitter"/>
                        </a>
                    </div>
                </div>
                <div className={styles.footer_products}>
                    <p className={styles.footer_links_header}>Popular</p>
                    <Link passHref href="/products/erectile-disfunction">
                        <a className={styles.footer_link}>
                            Erectile Dysfunction
                        </a>
                    </Link>
                    <Link passHref href="/products/premature-ejaculation">
                        <a className={styles.footer_link}>
                            Premature Ejaculation
                        </a>
                    </Link>
                    <Link passHref href="/login">
                        <a className={styles.footer_link}>
                            Login
                        </a>
                    </Link>
                </div>
                <div className={styles.footer_support}>
                    <p className={styles.footer_links_header}>Let&apos;s talk</p>
                    <Link passHref href="/ambassadors-program">
                        <a className={styles.footer_link} target="_blank" rel="noreferrer">
                            Become an Ambassador
                        </a>
                    </Link>
                    <a
                        href="mailto:talktous@upfor.love"
                        className={`${styles.footer_link} txt-underline ${styles.contact_link}`}
                    >
                        talktous@upfor.love
                    </a>
                    <p className={styles.footer_sub_link}>
                        (If you are a customer seeking support)
                    </p>
                    <a
                        href="mailto:contact@upfor.love"
                        className={`${styles.footer_link} txt-underline ${styles.contact_link}`}
                    >
                        media@upfor.love
                    </a>
                    <p className={styles.footer_sub_link}>
                        (Important: FOR MEDIA ONLY. Do not use for customer service inquires.)
                    </p>
                </div>
                <div className={styles.footer_images}>
                    <img src="/assets/CreditCards.png" alt="Credit Cards"/>
                    <img src="/assets/HIPAA.png" alt="HIPAA" className={styles.footer_hipaa}/>
                </div>
            </div>
            <div className={styles.footer_bottom}>
                <span className={styles.footer_bottom_text}>© Copyright upfor.love</span>
                <Link passHref href="/privacy">
                    <a className={styles.footer_bottom_link} target="_blank" rel="noreferrer" onClick={resetPromotion}>
                        Privacy Policy
                    </a>
                </Link>
                <Link passHref href="/refund-policy">
                    <a className={styles.footer_bottom_link} target="_blank" rel="noreferrer" onClick={resetPromotion}>
                        Refund Policy
                    </a>
                </Link>
                <Link passHref href="/terms">
                    <a className={styles.footer_bottom_link} target="_blank" rel="noreferrer" onClick={resetPromotion}>
                        Terms and Conditions
                    </a>
                </Link>
                <a href="https://support.upfor.love/hc/en-us/articles/6141425637917-CCPA-Do-not-sell-my-personal-information" className={styles.footer_bottom_link} target="_blank" rel="noreferrer">
                    CCPA: Do Not Sell My Personal Information
                </a>
                <span className={styles.footer_bottom_text}>© Copyright upfor.love</span>
            </div>
        </div>
    );
};

export default Footer;
